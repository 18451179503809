import React, { useEffect, useRef } from 'react';
import H from 'history';
import { connect } from '@optum-uhone-hmkts/rise';
import { ContactListCard } from '../../components/contact/contact_list';
import { LeadListCard } from '../../components/lead/lead_list';
// import 'style-loader!css-loader!react-placeholder/lib/reactPlaceholder.css';
import 'react-placeholder/lib/reactPlaceholder.css';

import { NavigationProps, navRoutes } from '../../components/nav/Routes';
import {
	Icon,
	Dialog,
	DialogActions,
	Button,
	Grid,
	DialogContent,
	DialogTitle
} from '@material-ui/core';
import { navigateToWithoutAddingToHistory } from '../../actions/navigation_actions';
import { FabMenu } from '../../components/nav/fab_menu';
import {
	GetHousehold,
	RemoveContactFromHousehold,
	SetNewPrimaryContactOnHousehold,
	ReassignHousehold,
	saveHouseholdTags,
	HouseholdTagPayload,
} from '../../actions/household_actions';
import {
	Contact,
	HouseholdRole,
	mapEnumToHouseholdRole,
} from '../../reducers/ContactReducer';
import moment from 'moment';
import { jwt_auth } from '../../utilities/auth';
import {
	Loaded,
	isLoading,
	hasBLead,
	getHeaderSubTitlesToDisplay,
} from '../../utilities/utilities';
import { Activity } from '../../reducers/activity_reducer';
import {
	EditActivity,
} from '../../actions/activity_actions';
import { BasePageContainer } from '../../components/Layout/BasePage';
import { PersistentNotifications } from '../../components/notifications/notificationPersistent';
import { LeadStatusCard } from '../../components/lead/LeadStatusCard';
import { StoreInactiveLeadId } from '../../actions/lead_actions';
import { UpdateContact } from '../../actions/contact_actions';
import { NoteListCard } from '../../components/notes/note_list';
import { CompletedActivityListCard } from '../../components/activity/completed_activity_list';
import { OpenActivityListCard } from '../../components/activity/open_activity_list';
import { ProductListCard } from '../../components/product/product_list';
import { AttachmentListCard } from '../../components/attachment/attachment_list';
import { OwnershipListCard } from '../../components/lead/ownership_card';
import { Application } from '../../reducers/application_reducer';
import { AppOrPolicy, ProductStatus } from '../../reducers/policy_reducer';
import { ReassignDialog } from '../../components/reassign/reassign_dialog';
import { ContactHeaderBar } from '../../components/Layout/contact_header_bar';
import { Attachment } from '../../reducers/attachment_reducer';
import { HouseholdMetaData } from '../../reducers/household_reducer';
import { getPageNotes } from '../../selectors/note_selectors';
import { getPageAttachments } from '../../selectors/attachment_selectors';
import { getPageOwnershipHistory } from '../../selectors/ownership_history_seletors';
import { FormSlice } from '@optum-uhone-hmkts/rise';
import {
	getPageContacts,
	getPrimaryContact,
} from '../../selectors/contact_selectors';
import { getPageLeads, getOpenLead, getMostRecentPageLead } from '../../selectors/lead_selectors';
import {
	getPageActivities,
	getNextActivity,
	getQuoteActivitiesAsActivities,
} from '../../selectors/activity_selectors';
import { getPageApplications } from '../../selectors/application_selectors';
import { getPagePolicies } from '../../selectors/policy_selectors';
import { getId } from '../../selectors/base_selectors';
import { NextActivityCard } from '../../components/activity/next_activity_card';
import { WideButton } from '../../components/utility/wide_button';
import { AdminInfo } from '../../components/admin/admin_info';
import {
	getCurrentAgentDisplayName,
	getCurrentAgentCode,
} from '../../selectors/agent_selectors';
import { Lookup, Lookups } from '../../utilities/lookup';
import { getPageHouseholdMetaData } from '../../selectors/household_selectors';
import { themePalette, themeLinks } from '../../utilities/branding';
import { FollowUpActivityType } from '../activity/upsert_activity';
import { isMobileDevice } from '../../utilities/is_mobile';
import { MoreMenu, createMenuAction } from '../../components/nav/more_menu';
import { P } from '../../utilities/auth/permissions';
import Share, { ClientConnectShareFormValues } from '../client_connect/client_connect_share';
import { sum } from '../../utilities/array_util';
import { sortOldToNew } from '../../utilities/sort_util';
import { get } from '../../utilities/object_util';
import {
	LeadPage, StateProps as LeadStateProps, DispatchProps as LeadDispatchProps,
	ComponentProps as LeadComponentProps, State as LeadState, mapDispatchToProps as leadMapDispatchToProps
} from '../lead_page';
import { getLeadRouteOriginPage } from '../../selectors/lead_route_selectors';
import { composePhoneMetaData, composeEmailMetaData } from '../../utilities/phone_email_util';
import { Strings } from '../../assets/common/strings';
import { SelectContactDialog } from '../dialogs/select_contact_dialog';
import { EMPTY_LEAD } from '../../utilities/empty_entities';
import { isCompanyLead, isMedicareLead } from '../../utilities/lead_util';
import { TagsList } from '../../components/lead/tags_list';
import { ConnectureSSOCardComponent } from '../../components/lead/connecture_SSO_card';
import { PhoneTypes } from '@optum-uhone-hmkts/rise/lib/domain/form/constants/ac_type/ac_phone';
import { submitLog } from '../../utilities/logging_util';
import { openExternalLink } from '../../utilities';
import { isConnectureDRXAllowed } from '../../utilities/agent_util';
import { Agent } from '../../reducers/agent_reducer';
import { isContactMedicareEligible, hasContactsMedicareEligible } from '../../utilities/contact_util';

interface StateProps extends LeadStateProps {
	activitiesLoading: boolean;
	contacts: Loaded<Contact>[];
	householdId: string;
	householdMetaData: Loaded<HouseholdMetaData>;
	isContactLoading: boolean;
	isLeadLoading: boolean;
	agent: Agent;
}

interface DispatchProps extends LeadDispatchProps {
	editActivity: (activityToEdit: Activity) => void;
	getHousehold: (id: string) => void;
	reassignHousehold: (
		householdId: string,
		agentCode: string,
		agentName: string,
		history: H.History
	) => void;
	riseSetActivecontactId: (contactId: string) => void;
	removeContactFromHousehold: (householdId: string, contactId: string) => void;
	setNewPrimaryContact: (householdId: string, newPrimaryId: string) => void;
	storeInactiveLeadId: (leadId: string) => void;
	updateContact: (contact: Contact) => void;
	saveHouseholdTags: (payload: HouseholdTagPayload) => void;
}

interface ComponentProps extends LeadComponentProps {
}

interface HouseholdState extends LeadState {
	clientConnectDialogIsOpen: boolean;
	clientConnectFormValues: ClientConnectShareFormValues;
	contactDialogIsOpen: boolean;
	deleteModalVisible: boolean;
	mostRecentLead: any;
	open: boolean;
	sequence: number;
}

type HouseholdPageProps = DispatchProps &
	StateProps &
	NavigationProps &
	ComponentProps;

class HouseholdPage extends LeadPage<
	HouseholdPageProps,
	HouseholdState
> {

	constructor(props: HouseholdPageProps) {
		super(props);
		this.state = {
			open: false,
			sequence: 0,
			leadAgentSearched: false,
			mostRecentLead: {},
			createNoteDialogOpen: false,
			addAttachmentDialogIsOpen: false,
			contactDialogIsOpen: false,
			clientConnectDialogIsOpen: false,
			clientConnectFormValues: {
				FirstName: '',
				LastName: '',
				PhoneNumber: '',
				Email: '',
				Message: 'My information is now at your fingertips. Please schedule an appointment or request a quote anytime!',
			},
			showReassignDialog: false,
			deleteModalVisible: false,
			contactId: '',
		};

		this.handleReassignToAgent = this.handleReassignToAgent.bind(this);
	}

	componentWillMount() {
		if (
			!isLoading(this.props.contacts) &&
			!isLoading(this.props.leads) &&
			!isLoading(this.props.activities) &&
			!isLoading(this.props.applications) &&
			!isLoading(this.props.policies) &&
			!isLoading(this.props.householdMetaData)
		) {
			this.props.getHousehold(this.props.householdId);
		}
	}

	componentWillReceiveProps(nextProps: HouseholdPageProps) {
		if (this.props.householdId != nextProps.householdId) {
			this.props.getHousehold(nextProps.householdId);
		}

		if (
			nextProps.primaryContact &&
			nextProps.primaryContact.userId &&
			!this.state.leadAgentSearched
		) {
			this.props.getAgentWithAgentId(nextProps.primaryContact.userId);
			this.props.riseSetActivecontactId(nextProps.primaryContact.id);
			this.setState({
				leadAgentSearched: true,
			});
		}
	}

	componentWillUnmount() {
		this.props.riseSetActivecontactId('');
	}

	getMeta = () => ({
		householdId: this.props.match.params.householdID,
		createAppointmentUrl: navRoutes.appointmentCreate.path,
		editPhoneUrl: navRoutes.phoneEdit.path
			.replace(Strings.Navigation.Reschedule, FollowUpActivityType.PHONE_CALL),
	});
	getBaseUrl = () => navRoutes.household.path;
	replaceIdInUrl = (url: string) => url.replace(Strings.Navigation.HouseholdId, this.props.match.params.householdID);


	handleNavigateToProduct = (
		productId: string,
		policyOrApp: 'application' | 'policy'
	) => {
		if (policyOrApp === 'application') {
			this.props.setProductTab(AppOrPolicy.Application);
		} else {
			this.props.setProductTab(AppOrPolicy.Policy);
		}

		this.props.navigate(
			navRoutes.productDetail.path.replace(Strings.Navigation.ProductId, productId)
		);
	};

	handleNoSaleFollowUp = (status: Lookup, householdId: string) => {
		const followUpDescription = Lookups.NoSale.label + (status.label ? ' - ' + status.label : '');

		this.props.storeFollowupDescription({
			description: followUpDescription,
			time: moment(),
		});

		this.props.navigate(
			navRoutes.phoneEdit.path
				.replace(`${Strings.Navigation.HouseholdId}?`, householdId)
				.replace(Strings.Navigation.EmployerId, '0')
				.replace(Strings.Navigation.Reschedule, FollowUpActivityType.PHONE_CALL_3_MONTHS)
				.replace(Strings.Navigation.ActivityId, '0')
		);
		this.props.storeInactiveLeadId(this.props.openLead.id);

	}

	handleApplicationDeleteClick = (applicationToUpdate: Application) => {
		let _applicationToUpdate = Object.assign({}, applicationToUpdate);
		_applicationToUpdate.updatedByAgentName = this.props.currentAgentDisplayName;
		this.props.deleteApplication(_applicationToUpdate);
	};

	handleNavigateToActivity = (activity?: Activity) => {
		if (!activity) return;

		switch (activity.type) {
			case Strings.Activity.Task:
				this.props.navigate(
					navRoutes.taskDetail.path.replace(Strings.Navigation.ActivityId, activity.id)
				);
				break;

			case Strings.Activity.PhoneCall:
				this.props.navigate(
					navRoutes.phoneDetail.path.replace(Strings.Navigation.ActivityId, activity.id)
				);
				break;

			case Strings.Activity.Appointment:
				this.props.navigate(
					navRoutes.appointmentDetail.path.replace(Strings.Navigation.ActivityId, activity.id)
				);
				break;

			case Strings.Activity.LeadStatusChange:
				this.props.navigate(
					navRoutes.leadDetail.path
						.replace(Strings.Navigation.HouseholdId, activity.householdId || '')
						.replace(Strings.Navigation.LeadId, activity.lead || '')
						.replace(Strings.Navigation.FromAdvSearch, 'detail')
				);
				break;

			case Strings.Activity.Email:
				this.props.navigate(
					navRoutes.templatePreview.path
						.replace(Strings.Navigation.TemplateId, activity.description)
						.replace(Strings.Navigation.From, 'History')
						.replace(Strings.Navigation.EmailHistoryId, activity.id)
				);
				break;

			case Strings.Activity.Sms:
				activity.disposition && this.props.navigate(
					navRoutes.smsTemplatePreview.path
						.replace(Strings.Navigation.TemplateId, activity.disposition)
						.replace(Strings.Navigation.TemplatePageState, "Preview"));
				break;

			case Strings.Activity.Quoted:
				this.props.navigate(
					navRoutes.quoteActivityDetail.path
						.replace(Strings.Navigation.QuoteActivityId, activity.id)
				);
				break;
		}
	};

	handleNavigateToEditActivity = (activity: Activity) => {
		const route =
			activity.type == 'Phone Call'
				? navRoutes.phoneEdit
				: activity.type == Strings.Activity.Appointment
					? navRoutes.appointmentEdit
					: navRoutes.activityEdit;

		this.props.navigate(
			route.path
				.replace(Strings.Navigation.ActivityId, activity.id)
				.replace(Strings.Navigation.Reschedule, '0')
		);
	};

	handleActivityDeleteClick = (activityToUpdate: Activity) => {
		if (activityToUpdate.type == Strings.Activity.Quoted) {
			this.props.deleteQuoteActivity(activityToUpdate.id);
		} else {
			let _activityToUpdate = Object.assign({}, activityToUpdate);
			const currentTime = moment.utc().toDate();
			_activityToUpdate.updatedBy = this.props.userId;
			_activityToUpdate.modifiedByName = this.props.currentAgentDisplayName;
			_activityToUpdate.updatedOn = currentTime;
			this.props.deleteActivity(_activityToUpdate);
		}
	};

	handleFloatClick(destination: string) {
		this.props.navigate(destination);
	}

	handleDownloadAttachment = (attachment: Attachment) => {
		this.props.downloadAttachment({
			id: attachment.id,
			householdId: attachment.householdId,
			employerId: attachment.employerId,
			file: [],
			fileName: attachment.fileName,
			dateCreated: attachment.dateCreated,
		});
	};

	handleUpdateHouseholdRole = (contactToUpdate: Contact) => {
		if (contactToUpdate.householdRole == HouseholdRole.Secondary) {
			this.props.contacts.forEach(householdContact => {
				if (
					householdContact.data.householdRole == HouseholdRole.Secondary &&
					householdContact.data.id != contactToUpdate.id
				) {
					const priorSecondaryContact = {
						...householdContact.data,
						householdRole: HouseholdRole.Dependent,
					};
					this.props.updateContact(priorSecondaryContact);
				}
			});
		}
		this.props.updateContact(contactToUpdate);
	};

	renderFabMenu() {
		const icons: any[] = [
			{
				label: 'Attachment',
				icon: 'attach_file',
				onClick: () => {
					this.setState({ addAttachmentDialogIsOpen: true });
				},
			},
			{
				label: 'Application',
				icon: 'event',
				onClick: () => {
					this.handleFloatClick(
						navRoutes.applicationUpsert.path
							.replace(Strings.Navigation.HouseholdId, this.props.match.params.householdID)
							.replace(Strings.Navigation.ApplicationId, 'new')
					);
				},
			},
			{
				label: 'Add Lead',
				icon: 'perm_identity',
				onClick: () =>
					this.handleFloatClick(
						navRoutes.leadUpsert.path
							.replace(Strings.Navigation.HouseholdId, this.props.match.params.householdID)
							.replace(Strings.Navigation.LeadId, '0')
					),
				disabled: !jwt_auth.hasPermission(P.CreateLead) || this.props.hasActiveLead,
			},
			{
				label: 'Add Person',
				icon: 'perm_identity',
				onClick: () =>
					this.handleFloatClick(
						navRoutes.contactUpsert.path
							.replace(Strings.Navigation.HouseholdId, this.props.match.params.householdID)
							.replace(Strings.Navigation.ContactId, 'new')
					),
				disabled: !jwt_auth.hasPermission(P.ContactCreate),
			},
			{
				label: 'Phone Call',
				icon: 'local_phone',
				onClick: () => {
					this.props.navigate(
						navRoutes.phoneCreate.path
							.replace(Strings.Navigation.HouseholdId, this.props.householdId)
							.replace(Strings.Navigation.FollowUp, '0')
					);
				},
				disabled: !jwt_auth.hasPermission(P.CreateActivity),
			},
			{
				label: 'Appointment',
				icon: 'date_range',
				onClick: () => {
					this.props.navigate(
						navRoutes.appointmentCreate.path.replace(
							Strings.Navigation.HouseholdId,
							this.props.match.params.householdID
						)
					);
				},
				disabled: !jwt_auth.hasPermission(P.CreateActivity),
			},
			{
				label: 'Note',
				icon: 'event_note',
				onClick: () => this.setState({ createNoteDialogOpen: true }),
				disabled: false,
			},
		];

		return (
			<FabMenu
				disabled={this.props.isLeadLoading || this.props.isContactLoading}
				items={icons}
				placeholderPropsIds={['FactFinder-FactFinderFabItem', 'FactFinder-FactFinderFabItemSenior']}
				contacts={this.props.contacts.map(c => c.data)}
			/>
		);
	}

	setReassignHouseholdDialogVisibility(visible: boolean) {
		this.setState({
			showReassignDialog: visible,
		});
	}

	handleReassignToAgent(agentCode: string, agentName: string) {
		this.setState({ showReassignDialog: false });
		this.props.reassignHousehold(
			this.props.householdId,
			agentCode,
			agentName,
			this.props.history
		);
	}

	reassignLead = () => {
		this.setReassignHouseholdDialogVisibility(true);
	};

	openInsite = () => {
		const { contacts } = this.props;
		if (contacts.length === 0) {
			return;
		}

		const contactId = contacts[0]?.data?.id;
		submitLog(Strings.Log.Info, 'Pop-out to QC', { contactId });
		const url = `
			${themeLinks.quoteConnect}
			Quote/GetActivityCenterFamily?activityCenterContactId=
			${contactId}
        `;
		openExternalLink(url);
	};

	toggleContactDialog = () => {
		this.setState(prevState => ({ contactDialogIsOpen: !prevState.contactDialogIsOpen }));
	};

	shareClientConnect = (selected: { id: string; value: string; }) => {
		const contact = this.props.contacts.find(contact => contact.data.id === selected.id);
		const formValues: Partial<ClientConnectShareFormValues> = {
			FirstName: contact && contact.data.firstName,
			LastName: contact && contact.data.lastName,
		};
		if (isMobileDevice) {
			formValues.PhoneNumber = selected.value;
		} else {
			formValues.Email = selected.value;
		}
		this.setState(prevState => ({
			clientConnectDialogIsOpen: true,
			clientConnectFormValues: {
				...prevState.clientConnectFormValues,
				...formValues
			}
		}));
	};

	closeClientConnectShare = () => {
		this.setState({ clientConnectDialogIsOpen: false });
	};

	getMenuActions = (canReassign: boolean) => {
		const { clickToCallEnabled } = this.props;
		const contacts = this.props.contacts.map(c => c.data)
		const disabled = isMobileDevice
			? !composePhoneMetaData(contacts).phones.filter(p => p.type === PhoneTypes.Mobile).length
			: !composeEmailMetaData(contacts).emails.length;

		const menuActions = [
			createMenuAction(Strings.MoreMenu.Reassign, this.reassignLead, !canReassign || clickToCallEnabled),
			createMenuAction(Strings.MoreMenu.Quote, this.openInsite),
		];
		if (jwt_auth.hasPermission(P.ClientConnect)) {
			menuActions.push(createMenuAction(Strings.MoreMenu.ClientConnect, this.toggleContactDialog, disabled));
		}
		menuActions.push(
			this.createPrintMenuAction(),
			this.createHelpMenuAction(),
		);
		return menuActions;
	}

	saveTags = (tags: string[]) => {
		const { householdId, saveHouseholdTags } = this.props;
		saveHouseholdTags({
			householdId,
			tags,
		});
	};

	render() {
		const {
			activities, clickToCallEnabled, hasActiveBusinessRelationship, mostRecentPageLead,
			openLead, notes, deleteNote, applications, policies, attachments, deleteAttachment,
			leads, contacts, householdMetaData, userId, agent
		} = this.props;

		const openActivities = activities
			.filter(
				activity => activity.data?.status?.toLowerCase() === Strings.ActivityStatus.Open
					&& activity.data?.userId === userId
			);
		const openActivitiesOldToNew = sortOldToNew(openActivities, activity => activity.data.time)

		const completedActivities = this.getCompletedActivities()

		const leadAgent = `${this.props.leadAgent.preferredOrFirstName} ${this.props.leadAgent.lastName}`;

		const isConnectureAllowed = isConnectureDRXAllowed(agent?.fieldOfficeInfo?.FieldOfficeName, this.props.primaryContact?.addresses[0]?.state ?? '', this.props.currentAgentCode);

		const getHouseholdRole = (contact: Contact) => {
			const role = contact.householdRole;
			return mapEnumToHouseholdRole(role);
		};
		const roleSpan = {
			setSpanText: getHouseholdRole,
			newCss: {
				float: 'right',
				marginRight: 20,
				fontSize: 'small',
			} as React.CSSProperties,
		};

		const goToDetails = this.getGoToDetailsFunction();

		const subtitles = getHeaderSubTitlesToDisplay(this.props.primaryContact);

		let annualHouseholdIncome = sum(this.props.contacts, contact => contact.data.annualIncome || 0);
		if (annualHouseholdIncome == 0) {
			annualHouseholdIncome = this.props.primaryContact.annualHouseHoldIncome || 0;
		}

		const connectureDRXCard = isConnectureAllowed ? (
			<ConnectureSSOCardComponent
				contacts={contacts} />
		) : null;

		const annualHouseholdIncomeCard =
			annualHouseholdIncome > 0 ? (
				<WideButton
					primary={'Annual Household Income'}
					secondary={
						'$' +
						String(annualHouseholdIncome.toFixed(2)).replace(
							/\B(?=(\d{3})+(?!\d))/g,
							','
						)
					}
					disabled
				/>
			) : null;

		const hasNoPolicies = this.props.policies.length == 0;
		const hasNoApplications = this.props.applications.length == 0;

		const canReassign = hasNoPolicies && hasNoApplications && !isContactMedicareEligible(this.props.primaryContact) && !hasContactsMedicareEligible(this.props.contacts) && !isMedicareLead(this.props.openLead);

		const menuActions = this.getMenuActions(canReassign);

		const shouldShowLeadNavigationArrows = this.getShouldShowLeadNavigationArrows();
		const hasNextLead = this.getHasNextLead();
		const hasPreviousLead = this.getHasPreviousLead();

		const productCount = (applications?.filter(app => !app.data?.isLinked).length || 0) + (policies?.length || 0);

		return (
			<BasePageContainer
				isAdvancedSearch={this.props.isAdvancedSearch}
				topComponent={
					<ContactHeaderBar
						title="Household"
						subtitles={subtitles}
						householdId={this.props.householdId}
						rightButtons={
							<MoreMenu
								children={menuActions}
							/>
						}
						contacts={this.props.contacts.map(contact => contact.data)}
						primaryContact={this.props.primaryContact}
						isLoading={
							this.props.isLeadLoading ||
							this.props.isContactLoading ||
							isLoading(this.props.householdMetaData) ||
							this.props.agent.isLoading
						}
						hideTopRow={!!this.props.navigateFromParent}
						goToDetails={goToDetails}
						navigatePreviousEnabled={shouldShowLeadNavigationArrows && hasPreviousLead && !clickToCallEnabled}
						navigateNextEnabled={shouldShowLeadNavigationArrows && hasNextLead && !clickToCallEnabled}
						navigateToLead={this.handleNavigateToNext}
						navigateTo={this.props.navigate}
						hasActiveBusinessRelationship={hasActiveBusinessRelationship}
					/>
				}
				bottomComponent={this.renderFabMenu()}
			>
				<PersistentNotifications />
				<Grid container style={{ marginBottom: this.props.isAdvancedSearch ? 80 : 30 }}>
					<Grid item lg={3} />
					<Grid item xs={12} lg={this.props.parentHistory ? 12 : 6}>
						<TagsList
							tags={householdMetaData?.data?.tags}
							saveTags={this.saveTags}
						/>
						{this.props.hasActiveLead ? (
							<LeadStatusCard
								lead={this.props.openLead || EMPTY_LEAD}
								updateLead={this.props.updateLead}
								navigateToHouseholdFollowup={this.handleNavigateToFollowup}
								lookups={this.props.lookups}
							/>
						) : null}
						<NextActivityCard
							nextActivity={this.props.nextActivity}
							onClick={() => this.handleNavigateToActivity(this.props.nextActivity)}
						/>
						<WideButton
							primary={'Quote'}
							secondary={'QuoteConnect'}
							disabled={contacts.length === 0}
							icon={<Icon style={{ marginRight: 0 }}>open_in_new</Icon>}
							onClick={this.openInsite}
						/>
						{connectureDRXCard}
						<ContactListCard
							isLoading={isLoading(contacts)}
							noPadding
							title={'People'}
							onContactClick={(contact: Contact) => {
								this.props.navigate(
									navRoutes.contactDetail.path
										.replace(
											Strings.Navigation.HouseholdId,
											this.props.match.params.householdID
										)
										.replace(Strings.Navigation.ContactId, contact.id)
								);
							}}
							deleteContactDisabled={
								contacts.length == 1 &&
								(policies.length > 0 ||
									applications.length > 0)
							}
							customSpans={[roleSpan]}
							contacts={contacts}
							parentId={this.props.householdId}
							setNewPrimaryContact={(contactId: string) =>
								this.props.setNewPrimaryContact(
									this.props.householdId,
									contactId
								)
							}
							removeContact={(contactId: string) =>
								this.props.removeContactFromHousehold(
									this.props.householdId,
									contactId
								)
							}
							updateHouseholdRole={this.handleUpdateHouseholdRole}
							deletePerson={this.props.deleteContact}
							policies={this.props.policies}
							returnToDetailPage={() => {
								this.props.navigate(
									navRoutes.household.path.replace(
										Strings.Navigation.HouseholdId,
										this.props.match.params.householdID
									)
								);
							}}
							goToLeadsDashboard={() => {
								this.props.navigate(navRoutes.leadDashboard.path);
							}}
							enableMenu
							deleteFromHousehold={true}
							editPerson={(contact: Contact) => {
								if (contact && contact.id && contact.householdId) {
									this.props.navigate(navRoutes.contactUpsert.path
										.replace(Strings.Navigation.HouseholdId, contact.householdId)
										.replace(Strings.Navigation.ContactId, contact.id));
								}
							}}
							contentCount={contacts.length}
						/>
						{annualHouseholdIncomeCard}
						<LeadListCard
							isLoading={isLoading(leads)}
							title={'Leads Summary'}
							leads={leads}
							noPadding
							parentId={get(() => this.props.match.params.householdID, 'missingHouseholdID')}
							startCollapsed={!!!openLead}
							contentCount={leads.length}
							onLeadClick={(householdID: string, leadID: string) => {
								if (this.props.isAdvancedSearch) {
									this.props.navigate(
										navRoutes.leadDetailAdvSearch.path
											.replace(Strings.Navigation.HouseholdId, householdID)
											.replace(Strings.Navigation.LeadId, leadID)
											.replace(Strings.Navigation.FromAdvSearch, 'true')
									);
								} else {
									this.props.navigate(
										navRoutes.leadDetail.path
											.replace(Strings.Navigation.HouseholdId, householdID)
											.replace(Strings.Navigation.LeadId, leadID)
									);
								}
							}}
						/>
						<ProductListCard
							title={'Products Summary'}
							applications={this.props.applications}
							isLoading={
								isLoading(this.props.applications) ||
								isLoading(this.props.policies)
							}
							policies={this.props.policies}
							handleApplicationDeleteClick={this.handleApplicationDeleteClick}
							handleProductClick={this.handleNavigateToProduct}
							startCollapsed
							contentCount={productCount}
							noPadding
						/>
						<OpenActivityListCard
							isLoading={isLoading(openActivitiesOldToNew)}
							title={'Open To-Do Summary'}
							openActivities={openActivitiesOldToNew}
							handleActivityDeleteClick={this.handleActivityDeleteClick}
							handleActivityEditClick={this.handleNavigateToEditActivity}
							handleNavigateToActivity={this.handleNavigateToActivity}
							startCollapsed={!openActivitiesOldToNew?.length}
							contentCount={openActivitiesOldToNew?.length}
							noPadding
						/>
						<CompletedActivityListCard
							title={'History Summary'}
							isLoading={isLoading(completedActivities)}
							completedActitvites={completedActivities}
							handleClick={this.handleNavigateToActivity}
							handleDeleteClick={this.handleActivityDeleteClick}
							startCollapsed
							contentCount={completedActivities.length}
							noPadding
						/>
						<NoteListCard
							title={'Notes'}
							isLoading={isLoading(notes)}
							notes={notes}
							handleDeleteNote={deleteNote}
							canDelete={true}
							createNoteDialogIsOpen={this.state.createNoteDialogOpen}
							updateCreateNoteDialogState={(isOpen: boolean) =>
								this.setState({ createNoteDialogOpen: isOpen })
							}
							updateNote={this.props.updateNote}
							handleSaveNote={this.handleSaveNote}
							createNoteFullScreen={!this.props.clickToCallEnabled}
							startCollapsed
							contentCount={notes.length}
							noPadding
						/>
						<AttachmentListCard
							isLoading={isLoading(attachments)}
							title={'Attachment Summary'}
							attachments={attachments.map(loadedAttachment => loadedAttachment.data)}
							onDelete={deleteAttachment}
							onDownload={this.handleDownloadAttachment}
							startCollapsed
							contentCount={attachments.length}
							noPadding
						/>
						{!hasBLead(this.props.leads) ||
							this.props.impersonatingId != this.props.userId ? (
							<OwnershipListCard
								title={'Ownership History'}
								leadAgent={leadAgent}
								ownershipHistory={this.props.ownershipHistories}
								createdOn={this.props.householdMetaData.data.createdOn}
								createdByName={this.props.householdMetaData.data.createdByName}
								startCollapsed
								noPadding
							/>
						) : null}
						<AdminInfo isAdvancedSearch={this.props.isAdvancedSearch} household={this.props.householdMetaData.data} showDisclaimer={!isCompanyLead(mostRecentPageLead)} />
					</Grid>
				</Grid>
				{this.renderAttachmentDialog()}
				<ReassignDialog
					visible={this.state.showReassignDialog}
					currentAgentCode={this.props.currentAgentCode}
					reassignToAgent={this.handleReassignToAgent}
					closeReassignDialog={() =>
						this.setState({ showReassignDialog: false })
					}
					fullScreen={!this.props.clickToCallEnabled}
				/>
				{<SelectContactDialog
					open={this.state.contactDialogIsOpen}
					handleClose={this.toggleContactDialog}
					activityType={Strings.Activity.ClientConnect}
					contacts={this.props.contacts.map(c => c.data)}
					handleSelection={(selection) => {
						if (selection.length)
							this.shareClientConnect(selection[0])
					}}
					hasActiveBusinessRelationship={hasActiveBusinessRelationship}
				/>}
				{jwt_auth.hasPermission(P.ClientConnect) &&
					<Dialog
						open={this.state.clientConnectDialogIsOpen}
						fullWidth
					>
						<DialogTitle>Share ClientConnect</DialogTitle>
						<DialogContent>
							<Share
								initialValues={this.state.clientConnectFormValues}
								onSend={this.closeClientConnectShare}
							/>
						</DialogContent>
						<DialogActions>
							<Button color="secondary" onClick={this.closeClientConnectShare}>
								Cancel
							</Button>
							<Button
								style={{
									backgroundColor: themePalette.accept_button,
									color: themePalette.negative_text,
								}}
								onClick={this.closeClientConnectShare}
							>
								Done
							</Button>
						</DialogActions>
					</Dialog>
				}
			</BasePageContainer>
		);
	}
}

function mapStateToProps(
	state: any,
	props: HouseholdPageProps
): StateProps {


	const householdId = getId(state, props);

	const sortedAttachments = getPageAttachments(state, props).sort((a, b) => {
		return (
			moment
				.utc(b.data.dateCreated)
				.local()
				.valueOf() -
			moment
				.utc(a.data.dateCreated)
				.local()
				.valueOf()
		);
	});

	const emailActivities = state.emailHistorySummary.emailHistorySummarySet.filter(
		emailActivity => emailActivity.householdId === householdId
	);

	const quoteActivities = getQuoteActivitiesAsActivities(state, props);

	const householdActivities = getPageActivities(state, props);

	const leads = getPageLeads(state, props);
	const mostRecentPageLead = getMostRecentPageLead(state, props);

	const policies = getPagePolicies(state, props);
	const applications = getPageApplications(state, props);
	const hasActiveBusinessRelationship =
		policies.some(
			policy =>
				policy.data.policyStatus == ProductStatus.Active ||
				policy.data.policyStatus == ProductStatus.Pending
		) ||
		applications.some(
			application =>
				application.data.applicationStatus == ProductStatus.Active ||
				application.data.applicationStatus == ProductStatus.Pending
		);

	return {
		notes: getPageNotes(state, props),
		attachments: sortedAttachments,
		ownershipHistories: getPageOwnershipHistory(state, props),
		isLeadLoading: state.lead.isLoading,
		isContactLoading: state.contact.isLoading,
		contacts: getPageContacts(state, props),
		leads,
		mostRecentPageLead,
		hasActiveLead: leads.some(lead =>
			Lookups.OpenStatusCode.matches(lead.data.statusCode)
		),
		activities: householdActivities.concat(emailActivities).concat(quoteActivities),
		leadAgent: state.agent.leadAgent,
		nextActivity: getNextActivity(state, props),
		applications: getPageApplications(state, props),
		policies,
		activitiesLoading: state.activity.isLoading,
		currentAgentCode: getCurrentAgentCode(state),
		currentAgentDisplayName: getCurrentAgentDisplayName(state),
		primaryContact: getPrimaryContact(state, props),
		openLead: getOpenLead(state, props),
		originPage: getLeadRouteOriginPage(state),
		householdId,
		userId: state.user.id,
		householdMetaData: getPageHouseholdMetaData(state, props),
		impersonatingId: state.user.impersonatingId,
		lookups: state.lookup,
		leadRoutes: state.leadRoutes.routes,
		leadRoutesIndex: state.leadRoutes.index,
		leadFilters: state.leadFilters,
		pageNumber: state.lead.leadListPageNumber,
		pageSize: state.lead.leadListPageSize,
		hasError: state.lead.error,
		moreLeadsToLoad: state.lead.moreLeadsToLoad,
		clickToCallEnabled: state.clickToCall.enabled,
		hasActiveBusinessRelationship,
		agent: state.agent
	};
}

function mapDispatchToProps(
	dispatch: any,
	ownProps: any
): DispatchProps & Partial<NavigationProps> {
	return {
		...leadMapDispatchToProps(dispatch, ownProps),
		riseSetActivecontactId: (contactId: string) => dispatch(FormSlice.actions.SetActiveContactId({ contactId: contactId })),
		getHousehold: (id: string) => dispatch(GetHousehold.started(id)),
		setNewPrimaryContact: (householdId: string, contactId: string) =>
			dispatch(
				SetNewPrimaryContactOnHousehold.started({ householdId, contactId })
			),
		removeContactFromHousehold: (householdId: string, contactId: string) =>
			dispatch(RemoveContactFromHousehold.started({ householdId, contactId })),
		updateContact: (contact: Contact) =>
			dispatch(UpdateContact.started(contact)),
		editActivity: (activityToEdit: Activity) => {
			dispatch(EditActivity.started(activityToEdit));
		},
		reassignHousehold: (
			householdId: string,
			agentCode: string,
			agentName: string,
			history: H.History
		) => {
			dispatch(
				ReassignHousehold.started({
					householdId,
					agentCode,
					agentName,
					history,
				})
			);
		},
		storeInactiveLeadId: (leadId: string) => {
			dispatch(StoreInactiveLeadId(leadId));
		},
		navigateToWithoutAddingToHistory: (route: string) =>
			dispatch(navigateToWithoutAddingToHistory(route)),

		saveHouseholdTags: (payload: HouseholdTagPayload) => dispatch(saveHouseholdTags.started(payload))
	};
}

export const HouseholdPageContainer = connect(
	mapStateToProps,
	mapDispatchToProps, true
)(HouseholdPage);
